// src/pages/EditorialDashboard/EditorialDashboard.tsx
import React, { useState, useEffect, useCallback } from 'react';
import styles from './EditorialDashboard.module.css';
import { Edit, FileText, CheckSquare, BookOpen, Settings } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { TBookDetails, TLlmModel } from '../../api/generated/types/common';
import { BasicDetails } from '../../components/Analysis/Basic/Basic';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import { post } from '../../api/apiUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { UploadedFileData } from '../../mocks/data/fileData';
import { NGramAnalysisSection } from '../../components/Analysis/NGramAnalysis/NGramAnalysis';
import { Evaluation } from '../../components/Analysis/Evaluation/Evaluation';
import { StoryBeatSection } from '../../components/Analysis/StoryBeats/StoryBeats';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';

export default function EditorialDashboard() {
  const [activeTab, setActiveTab] = useState('developmental');

  const tabs = [
    { id: 'developmental', label: 'Developmental', icon: Edit },
    { id: 'line', label: 'Line Edits', icon: FileText },
    { id: 'copy', label: 'Copy Edits', icon: CheckSquare },
    { id: 'proofread', label: 'Proofread', icon: BookOpen },
    { id: 'settings', label: 'Settings', icon: Settings },
  ];

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState<UploadedFileData | null>(null);
  const [currentVersionDetails, setCurrentVersionDetails] =
    useState<TBookDetails | null>(null);
  const [llmModel, setLlmModel] = useState<TLlmModel>('o3-mini');
  const llmModelOptions: TLlmModel[] = [
    'gpt-4o-mini',
    'o1',
    'o1-mini',
    'o3-mini',
  ];
  const [error, setError] = useState<string | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isAuthorNGramAnalyzing, setIsAuthorNGramAnalyzing] = useState(false);
  const [isStoryBeatAnalyzing, setIsStoryBeatAnalyzing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isAcceptingOrRejecting, setIsAcceptingOrRejecting] = useState(false);

  const hasAnyPendingAnalysis = useCallback(() => {
    if (!currentVersionDetails) return false;
    return (
      currentVersionDetails.evaluationPending ||
      currentVersionDetails.authorNGramAnalysisPending ||
      currentVersionDetails.characterNGramAnalysisPending ||
      currentVersionDetails.storyBeatAnalysisPending
    );
  }, [currentVersionDetails]);

  const fetchFileDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const response = await post<UploadedFileData>(`/files/book/${id}`, {});
      setFileDetails(response);
      setCurrentVersionDetails(response.mostRecentDetails);
      setError(null);
    } catch (error) {
      console.error('Error fetching file details:', error);
      setError('Failed to load file details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchFileDetails();
  }, [fetchFileDetails]);

  const fetchVersionDetails = useCallback(async (versionId: string) => {
    setIsLoading(true);
    try {
      const response = await post<TBookDetails>(
        `/files/book/version/${versionId}`,
        {}
      );
      setCurrentVersionDetails(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching version details:', error);
      setError('Failed to load version details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Only start polling if there's a pending analysis
    if (!hasAnyPendingAnalysis()) return;

    // Set up polling interval
    const pollInterval = setInterval(() => {
      if (currentVersionDetails) {
        fetchVersionDetails(currentVersionDetails.versionId);
      }
    }, 30000); // Poll every 30 seconds

    // Cleanup function to clear interval
    return () => {
      clearInterval(pollInterval);
    };
  }, [hasAnyPendingAnalysis, currentVersionDetails, fetchVersionDetails]);

  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = event.target.value as TLlmModel;
    setLlmModel(selectedModel);
  };

  const handleEvaluationSubmit = async () => {
    if (!id || !currentVersionDetails) return;

    setIsEvaluating(true);
    try {
      await post(`/evaluation`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting evaluation:', error);
      setError('Failed to submit evaluation. Please try again later.');
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleAuthorNGramAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsAuthorNGramAnalyzing(true);
    try {
      await post(`/ngram/author`, {
        bookVersionId: currentVersionDetails.versionId,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting author NGram analysis:', error);
      setError(
        'Failed to submit author NGram analysis. Please try again later.'
      );
    } finally {
      setIsAuthorNGramAnalyzing(false);
    }
  };

  const handleStoryBeatAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsStoryBeatAnalyzing(true);
    try {
      await post(`/story-beats`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting story beat analysis:', error);
      setError('Failed to submit story beat analysis. Please try again later.');
    } finally {
      setIsStoryBeatAnalyzing(false);
    }
  };

  const handleManuscriptAction = async (
    action: 'bookAccepted' | 'bookRejected'
  ) => {
    if (!id) return;

    setIsAcceptingOrRejecting(true);
    try {
      await post(`/files/book/${id}/action`, {
        action,
      });
      // Optionally refresh the file details after action
      await fetchFileDetails();
    } catch (error) {
      console.error('Error processing manuscript action:', error);
      setError('Failed to process manuscript action. Please try again later.');
    } finally {
      setIsAcceptingOrRejecting(false);
    }
  };
  const handleDeleteBook = async () => {
    if (!id) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${id}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/submissions');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!fileDetails || !currentVersionDetails) {
    return (
      <div className={styles.errorMessage}>No file details available.</div>
    );
  }

  return (
    <div className="container">
      <h1 className={styles.title}>Book Development Dashboard</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <BasicDetails
        currentVersionDetails={currentVersionDetails}
        fileDetails={fileDetails}
        handleManuscriptAction={handleManuscriptAction}
        isAcceptingOrRejecting={isAcceptingOrRejecting}
        displayActions={false}
      />

      <div className={styles.tabs}>
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''} ${tab.id === 'settings' ? styles.settings : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            <tab.icon className={styles.icon} />
            <span className={styles.tabLabel}>{tab.label}</span>
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>
        {activeTab === 'developmental' && (
          <div className={styles.analysisSections}>
            <nav className={styles.sectionNav}>
              <div className={styles.sectionLink}>Jump to</div>
              <button
                onClick={() =>
                  document
                    .getElementById('evaluation')
                    ?.scrollIntoView({ behavior: 'smooth' })
                }
                className={styles.sectionLink}
              >
                Evaluation
              </button>
              <button
                onClick={() =>
                  document
                    .getElementById('story-beats')
                    ?.scrollIntoView({ behavior: 'smooth' })
                }
                className={styles.sectionLink}
              >
                Story Beats
              </button>
            </nav>

            <section id="evaluation" className={styles.section}>
              <Evaluation
                evaluation={currentVersionDetails.evaluation}
                isPending={currentVersionDetails.evaluationPending}
                isEvaluating={isEvaluating}
                isOpen={true}
                onToggle={() => {}}
                onStartEvaluation={handleEvaluationSubmit}
                showVerdict={false}
              />
            </section>

            <section id="story-beats" className={styles.section}>
              <StoryBeatSection
                analysis={currentVersionDetails.storyBeatAnalysis}
                isPending={currentVersionDetails.storyBeatAnalysisPending}
                isAnalyzing={isStoryBeatAnalyzing}
                isOpen={true}
                onToggle={() => {}}
                onStartAnalysis={handleStoryBeatAnalysis}
              />
            </section>
          </div>
        )}
        {activeTab === 'line' && <p>Coming Soon</p>}
        {activeTab === 'copy' && (
          <NGramAnalysisSection
            title="Author NGram Analysis"
            analysis={currentVersionDetails.authorNGramAnalysis}
            isPending={currentVersionDetails.authorNGramAnalysisPending}
            isAnalyzing={isAuthorNGramAnalyzing}
            isOpen={true}
            onToggle={() => {}}
            onStartAnalysis={handleAuthorNGramAnalysis}
          />
        )}
        {activeTab === 'proofread' && <p>Coming Soon</p>}
        {activeTab === 'settings' && (
          <>
            {isAdmin && (
              <div className={styles.modelSelector}>
                <label htmlFor="modelSelect">Model: </label>
                <select
                  id="modelSelect"
                  value={llmModel}
                  onChange={handleModelChange}
                >
                  {llmModelOptions.map(model => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className={styles.deleteSection}>
              <h2 className={styles.sectionTitle}>Danger Zone</h2>
              <p className={styles.deleteWarning}>
                Deleting this book will permanently remove it and all its
                versions. This action cannot be undone.
              </p>

              <button
                className={styles.deleteButton}
                onClick={() => setShowDeleteDialog(true)}
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Delete Book'}
              </button>

              <AlertDialog
                isOpen={showDeleteDialog}
                title="Are you absolutely sure?"
                description={`This will permanently delete "${fileDetails.title}" and all its associated data. This action cannot be undone.`}
                onConfirm={handleDeleteBook}
                onCancel={() => setShowDeleteDialog(false)}
                confirmText="Yes, Delete Book"
                cancelText="Cancel"
                isConfirming={isDeleting}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
