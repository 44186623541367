// src/components/FileList/index.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UploadedFile } from '../FileUploader';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from './FileList.module.css';

interface FileListProps {
  files: UploadedFile[] | null | undefined;
  linkLocation: string;
}

export const FileList: React.FC<FileListProps> = ({ files, linkLocation }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Early return for null/undefined case
  if (!files) {
    return <p className={styles.message}>Loading...</p>;
  }

  if (!Array.isArray(files)) {
    console.error('Expected files to be an array, but got:', files);
    return <p className={styles.message}>Error: Unable to display files.</p>;
  }

  // Early return for empty array
  if (files.length === 0) {
    return <p className={styles.message}>No books uploaded yet.</p>;
  }

  // Calculate pagination values
  const totalPages = Math.ceil(files.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFiles = files.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  return (
    <div className={styles.container}>
      <div className={styles.listContainer}>
        <ul className={styles.fileList}>
          {currentFiles.map(file => (
            <li key={file.id} className={styles.fileItem}>
              <Link
                to={`/${linkLocation}/${file.id}`}
                className={styles.fileLink}
              >
                <span className={styles.fileName}>{file.title}</span>
                {file.versions && file.versions.length > 1 && (
                  <span className={styles.fileVersion}>
                    {file.versions.length} Versions
                  </span>
                )}
                <div className={styles.scoreAndDate}>
                  <span className={styles.fileScore}>
                    {file.overallScore
                      ? `Score: ${file.overallScore.toFixed(1)}`
                      : 'No score'}{' '}
                  </span>
                  <span className={styles.fileDate}>
                    {new Date(file.lastUploadDate).toLocaleDateString()}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={styles.paginationButton}
            aria-label="Previous page"
          >
            <ChevronLeft className={styles.paginationIcon} />
          </button>
          <span className={styles.pageInfo}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={styles.paginationButton}
            aria-label="Next page"
          >
            <ChevronRight className={styles.paginationIcon} />
          </button>
        </div>
      )}
    </div>
  );
};
