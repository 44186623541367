import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import styles from './Layout.module.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import SupportBanner from '../SupportBanner/SupportBanner';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isAuthenticated = useSelector(
    (state: RootState) => state.userAuth.isAuthenticated
  );
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (isAuthenticated) {
      navigate('/submissions');
    } else {
      navigate('/');
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.easterEggTop} aria-hidden="true" />
      <div className={styles.easterEggBottom} aria-hidden="true" />
      <SupportBanner />
      <Header />
      <div className={styles.bookSpine}>
        <div className={styles.spineText} onClick={handleLogoClick}>
          Familiar
        </div>
        <a
          href="https://infernalmoose.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.publisherLogo}
        >
          <img src="/images/IMP_white.svg" alt="Publisher Logo" />
        </a>
      </div>
      <main className={`${styles.main} ${isHomePage ? styles.homeMain : ''}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
