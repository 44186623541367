// src/pages/FileDetails/index.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { post } from '../../api/apiUtils';
import { UploadedFileData } from '../../mocks/data/fileData';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import styles from './FileDetails.module.css';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import { TBookDetails, TLlmModel } from '../../api/generated/types/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { StoryBeatSection } from '../../components/Analysis/StoryBeats/StoryBeats';
import { NGramAnalysisSection } from '../../components/Analysis/NGramAnalysis/NGramAnalysis';
import { Evaluation } from '../../components/Analysis/Evaluation/Evaluation';
import { BasicDetails } from '../../components/Analysis/Basic/Basic';

export const FileDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState<UploadedFileData | null>(null);
  const [currentVersionDetails, setCurrentVersionDetails] =
    useState<TBookDetails | null>(null);
  const [llmModel, setLlmModel] = useState<TLlmModel>('o3-mini');
  const llmModelOptions: TLlmModel[] = [
    'gpt-4o-mini',
    'o1',
    'o1-mini',
    'o3-mini',
  ];
  const [error, setError] = useState<string | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isAuthorNGramAnalyzing, setIsAuthorNGramAnalyzing] = useState(false);
  const [isStoryBeatAnalyzing, setIsStoryBeatAnalyzing] = useState(false);
  // const [isCharacterNGramAnalyzing, setIsCharacterNGramAnalyzing] =
  // useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // TODO: This is kind of hacky strings based on the titles of the sections and is really brittle.
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    evaluation: false,
    overall: false,
    character: false,
    plot: false,
    promise: false,
    quality: false,
    authorngramanalysis: false,
    characterngramanalysis: false,
    storybeat: false,
  });
  const [isAcceptingOrRejecting, setIsAcceptingOrRejecting] = useState(false);

  const hasAnyPendingAnalysis = useCallback(() => {
    if (!currentVersionDetails) return false;
    return (
      currentVersionDetails.evaluationPending ||
      currentVersionDetails.authorNGramAnalysisPending ||
      currentVersionDetails.characterNGramAnalysisPending ||
      currentVersionDetails.storyBeatAnalysisPending
    );
  }, [currentVersionDetails]);

  const fetchFileDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const response = await post<UploadedFileData>(`/files/book/${id}`, {});
      setFileDetails(response);
      setCurrentVersionDetails(response.mostRecentDetails);
      setOpenSections({
        overall: !!response.mostRecentDetails?.evaluation,
        authorngramanalysis: !!response.mostRecentDetails?.authorNGramAnalysis,
        characterngramanalysis:
          !!response.mostRecentDetails?.characterNGramAnalysis,
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching file details:', error);
      setError('Failed to load file details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchFileDetails();
  }, [fetchFileDetails]);

  const fetchVersionDetails = useCallback(async (versionId: string) => {
    setIsLoading(true);
    try {
      const response = await post<TBookDetails>(
        `/files/book/version/${versionId}`,
        {}
      );
      setCurrentVersionDetails(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching version details:', error);
      setError('Failed to load version details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Only start polling if there's a pending analysis
    if (!hasAnyPendingAnalysis()) return;

    // Set up polling interval
    const pollInterval = setInterval(() => {
      if (currentVersionDetails) {
        fetchVersionDetails(currentVersionDetails.versionId);
      }
    }, 30000); // Poll every 30 seconds

    // Cleanup function to clear interval
    return () => {
      clearInterval(pollInterval);
    };
  }, [hasAnyPendingAnalysis, currentVersionDetails, fetchVersionDetails]);

  const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVersionId = event.target.value;
    fetchVersionDetails(selectedVersionId);
  };

  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = event.target.value as TLlmModel;
    setLlmModel(selectedModel);
  };

  const handleEvaluationSubmit = async () => {
    if (!id || !currentVersionDetails) return;

    setIsEvaluating(true);
    try {
      await post(`/evaluation`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting evaluation:', error);
      setError('Failed to submit evaluation. Please try again later.');
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleAuthorNGramAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsAuthorNGramAnalyzing(true);
    try {
      await post(`/ngram/author`, {
        bookVersionId: currentVersionDetails.versionId,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting author NGram analysis:', error);
      setError(
        'Failed to submit author NGram analysis. Please try again later.'
      );
    } finally {
      setIsAuthorNGramAnalyzing(false);
    }
  };

  const handleStoryBeatAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsStoryBeatAnalyzing(true);
    try {
      await post(`/story-beats`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting story beat analysis:', error);
      setError('Failed to submit story beat analysis. Please try again later.');
    } finally {
      setIsStoryBeatAnalyzing(false);
    }
  };

  // const handleCharacterNGramAnalysis = async () => {
  //   if (!id || !currentVersionDetails) return;

  //   setIsCharacterNGramAnalyzing(true);
  //   try {
  //     await post(`/ngram/character`, {
  //       bookVersionId: currentVersionDetails.versionId,
  //     });
  //     await fetchVersionDetails(currentVersionDetails.versionId);
  //   } catch (error) {
  //     console.error('Error submitting character NGram analysis:', error);
  //     setError(
  //       'Failed to submit character NGram analysis. Please try again later.'
  //     );
  //   } finally {
  //     setIsCharacterNGramAnalyzing(false);
  //   }
  // };

  const handleManuscriptAction = async (
    action: 'bookAccepted' | 'bookRejected'
  ) => {
    if (!id) return;

    setIsAcceptingOrRejecting(true);
    try {
      await post(`/files/book/${id}/action`, {
        action,
      });
      // Optionally refresh the file details after action
      await fetchFileDetails();
    } catch (error) {
      console.error('Error processing manuscript action:', error);
      setError('Failed to process manuscript action. Please try again later.');
    } finally {
      setIsAcceptingOrRejecting(false);
    }
  };

  const toggleSection = (section: string) => {
    console.log(section);
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleDeleteBook = async () => {
    if (!id) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${id}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/submissions');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!fileDetails || !currentVersionDetails) {
    return (
      <div className={styles.errorMessage}>No file details available.</div>
    );
  }

  return (
    <div className={styles.container}>
      <Link to="/submissions" className={styles.backLink}>
        &larr; Back to Submissions
      </Link>
      <h1 className={styles.title}>{fileDetails.title}</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <div className={styles.card}>
        <h2 className={styles.sectionTitle}>Book Details</h2>
        <div className={styles.selectors}>
          {fileDetails.versions.length > 1 && (
            <div className={styles.versionSelector}>
              <label htmlFor="versionSelect">Version: </label>
              <select
                id="versionSelect"
                value={currentVersionDetails.versionId}
                onChange={handleVersionChange}
              >
                {fileDetails.versions.map(([versionNumber, versionId]) => (
                  <option key={versionId} value={versionId}>
                    Version {versionNumber}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isAdmin && (
            <div className={styles.modelSelector}>
              <label htmlFor="modelSelect">Model: </label>
              <select
                id="modelSelect"
                value={llmModel}
                onChange={handleModelChange}
              >
                {llmModelOptions.map(model => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <BasicDetails
          currentVersionDetails={currentVersionDetails}
          fileDetails={fileDetails}
          handleManuscriptAction={handleManuscriptAction}
          isAcceptingOrRejecting={isAcceptingOrRejecting}
        />

        <h2 className={styles.sectionTitle}>Analysis</h2>
        <div className={styles.analysisSections}>
          <NGramAnalysisSection
            title="Author NGram Analysis"
            analysis={currentVersionDetails.authorNGramAnalysis}
            isPending={currentVersionDetails.authorNGramAnalysisPending}
            isAnalyzing={isAuthorNGramAnalyzing}
            isOpen={openSections.authorngramanalysis}
            onToggle={() => toggleSection('authorngramanalysis')}
            onStartAnalysis={handleAuthorNGramAnalysis}
          />

          <Evaluation
            evaluation={currentVersionDetails.evaluation}
            isPending={currentVersionDetails.evaluationPending}
            isEvaluating={isEvaluating}
            isOpen={openSections.evaluation}
            onToggle={toggleSection}
            onStartEvaluation={handleEvaluationSubmit}
            showVerdict={true}
          />

          <StoryBeatSection
            analysis={currentVersionDetails.storyBeatAnalysis}
            isPending={currentVersionDetails.storyBeatAnalysisPending}
            isAnalyzing={isStoryBeatAnalyzing}
            isOpen={openSections.storybeat}
            onToggle={() => toggleSection('storybeat')}
            onStartAnalysis={handleStoryBeatAnalysis}
          />
          <p>Character Analysis Coming Soon</p>
          {/* COMING SOON
          {renderNGramAnalysisSection(
            'Character NGram Analysis',
            currentVersionDetails.characterNGramAnalysis,
            currentVersionDetails.characterNGramAnalysisPending,
            handleCharacterNGramAnalysis,
            isCharacterNGramAnalyzing
          )} */}
        </div>
        <div className={styles.deleteSection}>
          <h2 className={styles.sectionTitle}>Danger Zone</h2>
          <p className={styles.deleteWarning}>
            Deleting this book will permanently remove it and all its versions.
            This action cannot be undone.
          </p>

          <button
            className={styles.deleteButton}
            onClick={() => setShowDeleteDialog(true)}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Book'}
          </button>

          <AlertDialog
            isOpen={showDeleteDialog}
            title="Are you absolutely sure?"
            description={`This will permanently delete "${fileDetails.title}" and all its associated data. This action cannot be undone.`}
            onConfirm={handleDeleteBook}
            onCancel={() => setShowDeleteDialog(false)}
            confirmText="Yes, Delete Book"
            cancelText="Cancel"
            isConfirming={isDeleting}
          />
        </div>
      </div>
    </div>
  );
};
