import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { post } from '../../api/apiUtils';
import { TGetInviteResponse } from '../../api/generated/types/common';
import { loginSuccess } from '../../store/slices/userAuthSlice';
import { ApiError } from '../../api/errorHandling';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FieldError from '../../components/FieldError/FieldError';
import { LoginResponse } from '../Login/Login';

interface SignupCredentials {
  email: string;
  password: string;
  inviteId: string;
}

interface FieldErrors {
  [field: string]: string[] | undefined;
}

export const Invite: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [inviteInfo, setInviteInfo] = useState<TGetInviteResponse | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [credentials, setCredentials] = useState<SignupCredentials>({
    email: '',
    password: '',
    inviteId: id || '',
  });

  const fetchInvite = useCallback(async (inviteId: string) => {
    setIsLoading(true);
    try {
      const response = await post<TGetInviteResponse>(
        `/invites/${inviteId}`,
        {}
      );
      setInviteInfo(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching invite:', error);
      setError('Failed to get invite. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchInvite(id);
    }
  }, [id, fetchInvite]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
    setFieldErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[name];
      return newErrors;
    });
    setError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFieldErrors({});
    setError(null);

    try {
      const response = await post<LoginResponse>('/signup', credentials);
      if (response) {
        dispatch(loginSuccess(response.userDetails));
        window.location.href = '/submissions';
      }
    } catch (error) {
      console.error('Signup error:', error);
      if (error instanceof ApiError) {
        if (error.fieldErrors) {
          setFieldErrors(error.fieldErrors);
        } else {
          setError(error.message);
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Welcome to Familiar!</h1>
      {inviteInfo && (
        <div>
          <p>
            You've been invited to join{' '}
            <strong>{inviteInfo.organizationCallsign}</strong> as a{' '}
            <strong>{inviteInfo.role}</strong>.
          </p>
        </div>
      )}

      <form>
        {error && <ErrorMessage message={error} />}
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            required
          />
          {fieldErrors.email && <FieldError error={fieldErrors.email[0]} />}
        </div>

        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
          />
          {fieldErrors.password && (
            <FieldError error={fieldErrors.password[0]} />
          )}
        </div>

        <button type="submit" onClick={handleSubmit}>
          Create Account
        </button>
      </form>
    </div>
  );
};

export default Invite;
