// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login/Login';
import { AppDispatch } from './store';
import Layout from './components/Layout/Layout';
import About from './pages/About/About';
import Home from './pages/Home';
import Signup from './pages/Signup/Signup';
import Invite from './pages/Invite/Invite';
import { FileDetails } from './pages/FileDetails';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpiner';
import { checkSession } from './store/slices/userAuthSlice';
import PublicRoute from './components/PublicRoute';
import AccountSettingsPage from './pages/AccountSettings/AccountSettings';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import UserManagement from './pages/UserManagement/UserManagement';
import Bugs from './pages/Bugs/Bugs';
import { RootState } from './store/reducers/rootReducer';
import EditorialAnalysis from './pages/Product/EditorialAnalysis/EditorialAnalysis';
import ManuscriptAnalysis from './pages/Product/ManuscriptAnalysis/ManuscriptAnalysis';
import PromptManagement from './pages/PromptManagement/PromptManagement';
import Submissions from './pages/Submissions/Submissions';
import FeedbackPage from './pages/Feedback/FeedbackPage';
import StoryBeatAnalysis from './pages/Product/StoryBeats/StoryBeats';
import EditorialDashboard from './pages/EditorialDashboard/EditorialDashboard';
import './styles/global.css';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector(
    (state: RootState) => state.userAuth.isAuthenticated
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    // Set loading to false once we know the authentication state
    setIsLoading(false);
  }, [isAuthenticated]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Submissions /> : <Home />}
        />
        <Route path="/about" element={<About />} />
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/invites/:id" element={<Invite />} />
          <Route
            path="/products/editorial-analysis"
            element={<EditorialAnalysis />}
          />
          <Route
            path="/products/manuscript-analysis"
            element={<ManuscriptAnalysis />}
          />
          <Route path="/products/story-beats" element={<StoryBeatAnalysis />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/submissions" element={<Submissions />} />
          <Route path="/file/:id" element={<FileDetails />} />
          <Route path="/editorial/:id" element={<EditorialDashboard />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/account-settings" element={<AccountSettingsPage />} />
          <Route element={<AdminProtectedRoute />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/bugs" element={<Bugs />} />
            <Route path="/admin/prompts" element={<PromptManagement />} />
          </Route>
          {/* Add other protected routes here */}
        </Route>
      </Routes>
    </Layout>
  );
};

export default App;
